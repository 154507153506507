<template>
  <el-row :gutter="20" class="align-items-center" v-if="product.details">
    <el-col :md="is_sidebar == true ? 18 : 12">
      <div class="d-flex align-items-center">
        <img v-if="product.details.thumbnail_image" :src="productImage(product.details.thumbnail_image)"
          :alt="product.details.name" class="product-image">
        <div class="demo-image__error" v-else>
          <div class="block">
            <el-image />
          </div>
        </div>
        <div class="mx-20">
          <h3><router-link :to="`/product/${ product.details.slug}`">{{ product.details.name }}</router-link></h3>
          <h5>Color: {{ getColor(product.color_id)}}</h5>
          <h5>Size: {{ getAttributeValue(product.attribute_value_id)}}</h5>
          <!-- <p class="mt-2"><b>Price: </b>{{ product.details.price }}</p> -->
          <div>
            <p v-if="product.details.discount > 0"><b>Price: </b> {{ getDiscountPrice(product.details.price, product.details.discount) }}
            </p>

            <p v-if="product.details.discount > 0">
              <del class="text-muted"><b>Price: </b> {{ product.details.price }}</del>
              <span class="discount-percentage">-{{ product.details.discount }}%</span>
            </p>
            <p v-else><b>Price: </b> {{ product.details.price }} </p>
          </div>
          <br>
          <el-input-number v-model="product.quantity" :min="1" :max="10" v-if="is_sidebar" />
        </div>
      </div>

    </el-col>
    <el-col :md="6" class="text-center" v-if="!is_sidebar">
      <p class=" mt-0 text-muted">Quentity</p>
      <el-input-number v-model="product.quantity" :min="1" :max="10" />
    </el-col>
    <el-col :md="6" class="text-right">
      <el-button type="danger" circle @click="removeProduct(product)">
        <el-icon>
          <DeleteFilled />
        </el-icon>
      </el-button>
    </el-col>
  </el-row>
</template>

<script>
import axios from 'axios';

export default {
  name: 'CartProduct',
  props: {
    product: {},
    is_sidebar:{},
  },
  data(){
    return {
      colors: [],
      attribute_values: [],
    }
  },
  created() {
    this.getColors();
    this.getAttributeValues();
  },
  methods: {
    getColor(colorId){
      const color = this.colors.find(item => item.id === colorId);
      return color ? color.name : null;
    },
    getAttributeValue(valueId){
      const attr_value = this.attribute_values.find(item => item.id === valueId);
      return attr_value ? attr_value.value : null;    },
    productImage(img) {
      if (img) {
        return `${process.env.VUE_APP_API_URL}/${img}`;
      }
    },
    removeProduct(product) {
      this.$store.dispatch('shopingCart/removeCartItem', product);
    },
    getColors(){
      axios.get(`/api/web/colors`)
          .then(res => {
            this.colors = res.data.data
          })
    },

    getAttributeValues(){
      axios.get(`/api/web/attribute/values`)
          .then(res => {
            this.attribute_values = res.data.data
          })
    },
    getDiscountPrice(price, discount) {
      // eslint-disable-next-line radix
      if (discount != 'null') {
        const sum = (parseFloat(price) * parseFloat(discount)) / 100;
        return parseFloat(price) - sum;
      }
      return price;
    },
  }
};
</script>

<style scoped>
.product-image {
  height: 100px;
  width: 100px;
  object-fit: contain;
  object-position: center;
}

.demo-image__error {
  height: 100px;
  width: 100px;
}

.demo-image__error .block {
}

.demo-image__error .demonstration {
  display: block;
  color: var(--el-text-color-secondary);
  font-size: 14px;
  margin-bottom: 20px;
}

.demo-image__error .el-image {
  padding: 0 5px;
  max-width: 100px;
  max-height: 100px;
  width: 100px;
  height: 100px;
}

.demo-image__error .image-slot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: var(--el-fill-color-light);
  color: var(--el-text-color-secondary);
  font-size: 30px;
}

.demo-image__error .image-slot .el-icon {
  font-size: 30px;
}
</style>
